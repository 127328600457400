<template>
    <div class="page1">
        <EButton type="primary" @click="dialogVisible=true">增加</EButton>
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="false"
        >
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="showDialog(scope.row,'1')">
                            修改
                        </EButton>
                        <EButton type="text" @click="remove(scope.row,'1')">
                            删除
                        </EButton>
                        <EButton type="text" @click="change(scope.row.boothUseId,scope.row.status)">
                            {{scope.row.status==1?'禁用':'启用'}}
                        </EButton>
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog :dialogVisible="dialogVisible" :title="dialogForm.boothUseId?'修改摊位用途':'新增摊位用途'"
                 @handleClose="cancelDialog" @handleClick="saveData(dialogForm.boothUseId)"
                 width="30%"
                 :disabled="saveDisabled">
            <EForm
                    :formColumns="dialogFormColumns"
                    :rowSize="1"
                    :optionsBtn="false"
                    :actionBtn="true"
                    :formData="dialogForm"
                    ref="form"
                    :searchFlag="false"
                    @handleClose="cancelDialog"
                    :labelPosition="labelPosition"
                    :labelWidth="labelWidth"
                    :formRules="formRules"
            >
            </EForm>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";
  export default {
    name: 'Login',
    mixins:[otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '摊位用途',
            prop: 'boothUseName'
          },
          {
            label: '新建时间',
            prop: 'createTime'
          },
          {
            label: '新建人',
            prop: 'createUserCn'
          },
          {
            label: '最近一次修改时间',
            prop: 'updateTime',
          },
          {
            label: '修改人',
            prop: 'updateUserCn'
          },
          {
            label: '状态',
            prop: 'statusCn'
          },
        ],
        tableData: [],
        searchForm: {},
        count: null,
        dialogFormColumns: [
          {
            title: '摊位用途：',
            type: 'text',
            property: 'boothUseName',
            placeHolder: '最多可录入20字',
            show: true,
          },
        ],
        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        formRules: {
          boothUseName: vxRule(true, '', "blur", "摊位用途不能为空"),
          title: [vxRule(true, '', "blur", "标题不能为空"),
            vxRule(true, val => {
              return {result: val.length <= 20, errMsg: "最多可录入20字"}
            })
          ],
        },
        dialogForm: {
          boothUseName: '',
        },
        options: [],
      }
    },
    watch: {},
    created() {
      this.getData()
    },
    components: {ETable, EDialog, EButton, EForm},
    beforeMount() {

    },
    methods: {
      async changeStatus(id, status) {
        let res = await Http.cmboothuseUpdStatus({id, status})
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getData()
        }
      },
      change(id, status) {
        let msg = ''
        if (status == 1) {
          msg = '禁用'
        } else {
          msg = '启用'
        }
        this.$messageBox.confirm('确定' + msg + '？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res => {
          this.changeStatus(id, status == 1 ? 0 : 1)
        }).catch(res => {

        });
      },
      cancelDialog() {
        this.dialogVisible = false
        this.dialogForm = {
          boothUseName: '',
        }
        this.$refs.form.$refs.form.resetFields()
      },
      showDialog(row) {
        this.dialogVisible = true
        this.dialogForm.boothUseName = row.boothUseName
        this.dialogForm.boothUseId = row.boothUseId
      },
      saveData(boothUseId) {
        this.$refs['form'].$refs['form'].validate((valid, object) => {
          if (valid) {
            this.boothUseSubmit(boothUseId)
          } else {
            return false;
          }
        })
      },
      async getData() {
        let res = await Http.getBoothUseList()
        if (res.code == 200) {
          this.tableData = res.data
        }
      },
      //新增或者修改请求
      async boothUseSubmit(boothUseId) {
        this.setDisabled(true)
        let params = {
          boothUseName: this.dialogForm.boothUseName,
        }
        if (boothUseId) {
          params.boothUseId = boothUseId
        }
        let res = await Http.boothUseSubmit(params)
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.dialogVisible = false
          this.setDisabled(false)
          this.cancelDialog()
          this.getData()
        }
      },
      remove(row) {
        this.$messageBox.confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.boothUseRemove(row.boothUseId)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      async boothUseRemove(boothUseId) {
        let res = await Http.boothUseRemove({ids: boothUseId})
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getData()
        }
      },

    }
  }
</script>

<style lang="scss">

</style>
